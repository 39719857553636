<template>
  <div class="flex items-center">
    <svg
      class="block h-10 shrink-0"
      viewBox="0 0 300 150"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <mask id="a" fill="#fff">
        <path d="m0 0h210v113h-210z" fill="#fff" fill-rule="evenodd" />
      </mask>

      <g fill="none" fill-rule="evenodd">
        <path d="m0 0h300v150h-300z" fill-rule="nonzero" />

        <g fill="#043c7b" transform="translate(44 19)">
          <path
            d="m157.701546 13.2677782c.587796 4.2179352.16306 6.1162046-.054028 6.6877692-.245729.0033076-7.070478.0985684-11.521586.0985684.051424-2.6593632.166314-5.1143126-1.865586-6.9831437-3.141097-2.8872614-8.931182-2.4546187-12.209627-1.5731258-3.278119.8811621-7.2765 4.7101159-7.230609 8.8509825.051749 4.6224628 8.069341 4.9175066 12.640873 6.2280037 5.192525 1.4887803 12.970247 1.9415998 15.708413 8.9177974 2.486906 5.993821.606023 12.2502706-.377218 14.8004808-.982915 2.5502103-3.264449 6.9699131-7.136223 9.4255241s-10.155269 5.2793652-17.608174 5.2793652c-7.452904 0-11.953158-1.1414754-16.154631-4.131936-4.249643-3.0261833-6.273081-6.3133414-5.834349-12.588314.039056-.5718954.614484-4.4253259.710823-4.4253259h11.42004c-.043612 1.1957212-.399024 4.5093406.691947 6.7807147 1.09097 2.271374 5.201963 3.3764651 10.943554 3.3149425 5.741591-.0611918 8.621662-2.0256145 9.911819-3.6218939 1.290483-1.5959487 2.711479-4.5391096 1.728564-7.457463-.98259-2.9183534-7.501074-4.361488-9.622803-4.8192689-2.121404-.4577809-5.403755-1.0988065-9.353966-2.5700562-3.950538-1.470919-6.835491-3.9149531-7.911815-5.6435392-1.076325-1.7285861-1.929052-4.0396521-2.116197-7.2136881-.186819-3.1740359.097966-4.4835408.56241-6.5204013 2.169248-8.74712185 9.445097-13.63089021 15.408332-15.07931701 5.962909-1.44875756 14.340144-1.37201972 18.874897.30099758 5.84151 2.10235229 9.807018 7.7247224 10.39514 11.94232693"
            mask="url(#a)"
          />

          <path
            d="m12.4568024 1c2.6098239.02745458 41.054321 0 42.5431976 0-.5131213 2.72395779-1.9719696 9.9253282-2.2940315 11.297065-.0577468.0370471-29.7775325 0-29.8177902 0-.3118326 1.4031608-2.446483 10.474089-3.1856416 13.9839756h26.3285656c-.3362512 1.7117768-1.7875099 9.4824163-2.1359704 11.1816235 0 0-25.5481593 0-25.910479 0-.3982877 1.8437572-4.9434532 24.479896-5.4671339 26.5373359 0 0-9.58266335-.0036386-12.517519-.0036386 0-.086333 9.49323838-48.203964 12.4568024-62.9963614"
            mask="url(#a)"
          />

          <path
            d="m62.6208907 1c14.6515271 0 37.9860573.03999874 43.3791093.01289216-.4263 2.5453744-1.892562 9.74085034-2.171648 11.11204684-.028725.024462-30.9687577 0-31.0875733 0-.2683143 1.1801281-1.5478666 8.6767516-2.8470039 13.2742928h28.8304033c-.0280718.1857793-2.4566099 11.0551892-2.4967591 11.0551892-1.9506645 0-23.7624621 0-28.7788296 0-.2784332.9675728-2.7467941 15.0798558-3.0121707 16.4738615h32.5985545c-.1707158.5014718-2.2010259 11.0717175-2.3201679 11.0717175-.159944 0-33.7557006 0-44.7148053 0 4.1578923-21.3619721 8.4629984-41.6380279 12.6208907-63"
            mask="url(#a)"
          />

          <path
            d="m173.855279 3c1.4004.22533209 4.680034 1.5822661 6.169047 2.24113185 1.48934.65886575 6.406986 2.73001024 9.911432 5.11444535 9.159214 6.2318817 20.522574 15.4912516 20.049975 31.5672465-.472598 16.0763243-7.651166 26.0877894-12.479215 32.2096405-4.82805 6.1215217-16.215039 16.9101189-28.714603 23.8489636-12.287879 6.8212372-24.886229 10.4054672-30.36607 11.7330812-11.053544 2.678289-29.095306 4.591306-48.3336593 2.187764-17.274774-2.26584-36.8432898-8.683521-48.9546012-26.0508931-.1867419-.2678289-.1424358-.2042484-.1017398-.2042484 1.1099491 1.0416668 3.4650631 2.8291695 4.6816751 3.6777886 1.9228836 1.3404624 4.1723193 2.6100967 6.2084295 3.6781181 11.2970642 5.9251797 26.4658228 8.3860428 34.5022882 9.2965958 8.0367935.910552 24.9357865.407838 35.0116435-1.431056 11.093257-2.0428137 21.789399-5.5077887 25.647638-7.0488757 19.947907-7.9683224 50.175807-26.3569361 52.007453-53.2248226.599281-8.7800449-2.357739-16.3319642-7.313784-23.220735-3.229092-4.4829226-7.895342-8.10964914-12.373866-11.02743612-1.735157-1.13127249-3.813276-2.23619036-5.552043-3.34670858"
            mask="url(#a)"
          />
        </g>
      </g>
    </svg>

    <span class="font-bold text-primary-700">Meldeportal</span>
  </div>
</template>
